@font-face {
    font-family: 'Halvar Breitschrift';
    src: local('Halvar Breitschrift Lt'), local('Halvar-Breitschrift-Lt'),
        url('/static/fonts/HalvarBreit-Lt.woff2') format('woff2'),
        url('/static/fonts/HalvarBreit-Lt.woff') format('woff'),
        url('/static/fonts/HalvarBreit-Lt.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'Formular';
    src: local('Formular Regular'), local('Formular-Regular'),
    url('/static/fonts/Formular.woff2') format('woff2'),
    url('/static/fonts/Formular.woff') format('woff'),
    url('/static/fonts/Formular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Halvar Breitschrift';
    src: local('Halvar Breitschrift Md'), local('Halvar-Breitschrift-Md'),
    url('/static/fonts/HalvarBreit-Md.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
